 
import Actualites from "../component/Actualites";
import Projets from "../component/Projets";
import Diplomes from "../component/Diplomes";
import Liens from "../component/Liens";
import Competences from "../component/Competences";
import Coordonnees from "../component/Coordonnees";
import Fonctions from "../component/Fonctions";
import Loisirs from "../component/Loisirs";
import Streaming from "../component/Streaming";
import Planning from "../component/Planning";
import Rencontres from "../component/Rencontres";
  
function Content() {
    return (
       <main>  

        
          
         <Coordonnees></Coordonnees>
         <Fonctions></Fonctions> 
         
         <section id="competences" className="container-fluid">  

         
         <div className="row  row-cols-sm-1 row-cols-xs-1 row-cols-lg-2">
        
         <Actualites></Actualites> 
         <Competences></Competences>
         <Projets></Projets>

        <Diplomes></Diplomes>

        <Liens></Liens>
        <Loisirs></Loisirs>
        <Streaming></Streaming>

        <Planning></Planning>
        <Rencontres></Rencontres>
           </div>
           </section>


       
       </main>
    );
  }
  
  export default Content;
  