
import logo1 from './../../assets/img/palestinian.png';
import logo2 from './../../assets/img/destombes.png';
import logo3 from './../../assets/img/marketyourself.png';

function Projets() {
    return (
        <section id="Projets">
             <h2>
           Projets</h2>
          <div className="row m-0 row-cols-sm-1 row-cols-xs-1 row-cols-lg-1">
          <div className="col m-0 p-0">
          
           
          
           
 

            
              <div className="row m-0">
                <div className="card col-md-3 p-0 text-center"><a href="https://palestinian.fr" target="_blank"><img src={logo1} className="App-logo" alt="logo" /></a>
                  
                </div>
                <div className="col-md-9 ">
                  <div className="card-body">
                    <p><a href="https://palestinian.fr" target="_blank">https://palestinian.fr</a></p>
                    <p>Restaurant Palestinian & Vegan</p>
                  </div>
                </div>
              </div>
           


            
            
              <div className="row m-0">
                <div className="card col-md-3   p-0 text-center">
                <p> <a href="https://patisseriedestombes.fr/" target="_blank"><img src={logo2} className="App-logo" alt="logo" /></a></p>
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                  <p><a href="https://patisseriedestombes.fr/" target="_blank">https://patisseriedestombes.fr/</a></p>
            <p>BOULANGERIE – PÂTISSERIE - CHOCOLATERIE – TRAITEUR</p>
                  </div>
                </div>
              </div>
            

            
            
              <div className="row m-0">
                <div className="col-md-3 card  p-0  text-center"><div className="card-body">
                <p> <a href="https://marketyourself.fr" target="_blank"><img src={logo3} className="App-logo" alt="logo" /></a></p>
                </div></div>
                <div className="col-md-9">
                  
                  <p><a href="https://marketyourself.fr" target="_blank">https://marketyourself.fr</a></p>
                  <p>Restaurant Palestinian & Vegan</p>
                  
                </div>
              
            </div>
 


            
            </div>
            </div>
            
            </section>
    );
  }
  
  export default Projets;
  