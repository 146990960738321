function Coordonnees() {
  return (
    <section id="coordonnees" className="container-fluid mt-5  ">
      <article>


        <div className="mb-3">
          <div className="row row-cols-lg-2 row-cols-sm-2 row-cols-xs-2  g-0">
            <div className="col-4 p-0 m-0 text-end"> <svg width="100%" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="54" cy="54" r="54" fill="#3E3E3E" />
            </svg>
            </div>
            <div className="col p-0 m-0 text-start">

              <h1>Camille BINET</h1><p>35 ans</p>
              <p className="badge bg-secondary">
                <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.66668 0.791672H3.33334C2.01918 0.791672 0.958344 1.85251 0.958344 3.16667V15.8333C0.958344 17.1475 2.01918 18.2083 3.33334 18.2083H9.66668C10.9808 18.2083 12.0417 17.1475 12.0417 15.8333V3.16667C12.0417 1.85251 10.9808 0.791672 9.66668 0.791672ZM8.08334 16.625H4.91668V15.8333H8.08334V16.625ZM10.6563 14.25H2.34376V3.16667H10.6563V14.25Z" fill="white" />
                </svg>
                07 82 89 54 84</p>

            </div>
          </div>
        </div>










      </article>
    </section>
  );
}

export default Coordonnees;
