function Rencontres() {
    return (
      <article className="col loisirs ">
       
      <h2>Rencontres</h2>
      <p>Lieu de rencontres</p>
       <div className="card">
        <div className="card-body">
       <p>Espace de co-working 
       </p></div></div>
       
       
       <div className="card">
        <div className="card-body">
       <p>Espace de co-working
       </p></div></div>
       
       <div className="card">
        <div className="card-body">
       <p>Espace de co-working   
       </p></div></div>
       
       
       <div className="card">
        <div className="card-body">
       <p>Espace de co-working
       </p></div></div>
      
       
        

       </article>
    );
  }
  
  export default Rencontres;
  