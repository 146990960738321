function Competences() {
    return (
      <article className="col competences ">
       
      <h2> Compétences</h2>
       <div className="card">
        <div className="card-body">
       <p>Logos 
       </p></div></div>
       
       
       <div className="card">
        <div className="card-body">
       <p>Applications web 
       </p></div></div>
       
       <div className="card">
        <div className="card-body">
       <p>Applications mobiles 
       </p></div></div>
       
       
       <div className="card">
        <div className="card-body">
       <p>Photographies
       </p></div></div>
       
        

       </article>
    );
  }
  
  export default Competences;
  