 
function Header() {
    return (

        <header className="App-header container-fluid ">
           <section   className="row row-cols-lg-3 row-cols-sm-3 row-cols-xs-3 p-3 m-0 pt-4">
                <div id="date" className="text-start col m-0 p-0">
                    <p><svg className="me-2" width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.8333 2.375H14.0416V0.791664H12.4583V2.375H4.54165V0.791664H2.95831V2.375H2.16665C1.29581 2.375 0.583313 3.0875 0.583313 3.95833V16.625C0.583313 17.4958 1.29581 18.2083 2.16665 18.2083H14.8333C15.7041 18.2083 16.4166 17.4958 16.4166 16.625V3.95833C16.4166 3.0875 15.7041 2.375 14.8333 2.375ZM14.8333 16.625H2.16665V6.33333H14.8333V16.625Z" fill="white"/>
</svg> 
20/01/2023</p>
                </div>
    <div id="heure" className="text-center col m-0 p-0">
                    <p> <svg  className="me-2"  width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49212 0.583336C4.12212 0.583336 0.583374 4.13 0.583374 8.5C0.583374 12.87 4.12212 16.4167 8.49212 16.4167C12.87 16.4167 16.4167 12.87 16.4167 8.5C16.4167 4.13 12.87 0.583336 8.49212 0.583336ZM8.50004 14.8333C5.00087 14.8333 2.16671 11.9992 2.16671 8.5C2.16671 5.00084 5.00087 2.16667 8.50004 2.16667C11.9992 2.16667 14.8334 5.00084 14.8334 8.5C14.8334 11.9992 11.9992 14.8333 8.50004 14.8333Z" fill="white"/>
<path d="M8.89587 4.54166H7.70837V9.29166L11.8646 11.7854L12.4584 10.8117L8.89587 8.69791V4.54166Z" fill="white"/>
</svg> 
 09:20
                    </p>
                </div>
                <div id="help" className="text-end col m-0 p-0 ">
                    <svg  className="me-2"  width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.49998 0.583336C4.12998 0.583336 0.583313 4.13 0.583313 8.5C0.583313 12.87 4.12998 16.4167 8.49998 16.4167C12.87 16.4167 16.4166 12.87 16.4166 8.5C16.4166 4.13 12.87 0.583336 8.49998 0.583336ZM9.29165 12.4583H7.70831V7.70834H9.29165V12.4583ZM9.29165 6.125H7.70831V4.54167H9.29165V6.125Z" fill="white"/>
                    </svg> 
                    info

                </div>

            
            </section>


        </header>
    );
}

export default Header;
