
import "../../assets/js/script.js";

function Planning() {
  return (
    <article className="col loisirs ">

      <h2> Planning</h2>
      <p>Jour de stream</p>

      <div id="carouselExampleSlidesOnly myCarousel" class="carousel slide" data-bs-ride="carousel" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div className="card"> <h3 className="m-4">Lundi</h3>
              <div className="card-body text-start">
               
                <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
                <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
                <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p>
              </div>
            </div>
          
          </div>
          <div class="carousel-item">
            <div className="card"><h3 className="m-4">Mardi</h3>
              <div className="card-body text-start">
                
                <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
                <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
                <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p>
              </div>
            </div>
         </div>
         
         <div class="carousel-item">
            <div className="card"><h3 className="m-4">Mercredi</h3>
            <div className="card-body text-start">
               
               <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
               <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
               <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p></div></div>
         </div>

         <div class="carousel-item">
            <div className="card"><h3 className="m-4">Jeudi</h3>
            <div className="card-body text-start">
               
               <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
               <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
               <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p></div></div>
         </div>

 

         <div class="carousel-item">
            <div className="card"><h3 className="m-4">Vendredi</h3>
            <div className="card-body text-start">
               
               <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
               <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
               <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p></div></div>
         </div>

         <div class="carousel-item">
            <div className="card"><h3 className="m-4">Samedi</h3>
            <div className="card-body text-start">
               
               <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
               <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
               <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p></div></div>
         </div>

         <div class="carousel-item">
            <div className="card"><h3 className="m-4">Dimanche</h3>
            <div className="card-body text-start">
               
               <p><span class="badge bg-primary">9:00 - 12:00</span> Twitch</p>
               <p><span class="badge bg-primary">14:00 - 16:00</span> Youtube</p>
               <p><span class="badge bg-primary">21:00 - 23:00</span> Twitch</p></div></div>
         </div>
 
        </div>
      </div>
 


    </article>
  );
}

export default Planning;
