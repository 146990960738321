function Liens() {
    return (
        <section>
        <div className="row m-0 p-0 ">
           <div id="liens" className="m-0 p-0">
              <h2>
                
                 
                 Liens
              </h2>
              
              <article>
                 <p>
                 <svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(90 17 17)" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M17.5 9.58334C13.13 9.58334 9.58337 13.13 9.58337 17.5C9.58337 21.87 13.13 25.4167 17.5 25.4167H21.4584V23.8333H17.5C14.0642 23.8333 11.1667 20.9358 11.1667 17.5C11.1667 14.0642 14.0642 11.1667 17.5 11.1667C20.9359 11.1667 23.8334 14.0642 23.8334 17.5V18.6321C23.8334 19.2575 23.2713 19.875 22.6459 19.875C22.0205 19.875 21.4584 19.2575 21.4584 18.6321V17.5C21.4584 15.315 19.685 13.5417 17.5 13.5417C15.315 13.5417 13.5417 15.315 13.5417 17.5C13.5417 19.685 15.315 21.4583 17.5 21.4583C18.5925 21.4583 19.59 21.015 20.3025 20.2946C20.8171 20.9992 21.7038 21.4583 22.6459 21.4583C24.2055 21.4583 25.4167 20.1917 25.4167 18.6321V17.5C25.4167 13.13 21.87 9.58334 17.5 9.58334ZM17.5 19.875C16.1859 19.875 15.125 18.8142 15.125 17.5C15.125 16.1858 16.1859 15.125 17.5 15.125C18.8142 15.125 19.875 16.1858 19.875 17.5C19.875 18.8142 18.8142 19.875 17.5 19.875Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
<a href="mailto:webdesign@camille-binet.com" target="_blank">
                     webdesign@camille-binet.com
                     </a> </p>
                    
              </article>
                    
              <article>
                 <p>  <svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(90 17 17)" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M22.25 14.3334H21.4583V12.75C21.4583 10.565 19.685 8.79169 17.5 8.79169C15.315 8.79169 13.5416 10.565 13.5416 12.75V14.3334H12.75C11.8791 14.3334 11.1666 15.0459 11.1666 15.9167V23.8334C11.1666 24.7042 11.8791 25.4167 12.75 25.4167H22.25C23.1208 25.4167 23.8333 24.7042 23.8333 23.8334V15.9167C23.8333 15.0459 23.1208 14.3334 22.25 14.3334ZM17.5 21.4584C16.6291 21.4584 15.9166 20.7459 15.9166 19.875C15.9166 19.0042 16.6291 18.2917 17.5 18.2917C18.3708 18.2917 19.0833 19.0042 19.0833 19.875C19.0833 20.7459 18.3708 21.4584 17.5 21.4584ZM19.9541 14.3334H15.0458V12.75C15.0458 11.3963 16.1462 10.2959 17.5 10.2959C18.8537 10.2959 19.9541 11.3963 19.9541 12.75V14.3334Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
<a href="https://camille-binet.com" target="_blank"> 
https://camille-binet.com
                   </a>  </p>
                 </article>

                 <article>
                 <p>
                  
                  <svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(90 17 17)" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M22.25 14.3334H21.4583V12.75C21.4583 10.565 19.685 8.79169 17.5 8.79169C15.315 8.79169 13.5416 10.565 13.5416 12.75V14.3334H12.75C11.8791 14.3334 11.1666 15.0459 11.1666 15.9167V23.8334C11.1666 24.7042 11.8791 25.4167 12.75 25.4167H22.25C23.1208 25.4167 23.8333 24.7042 23.8333 23.8334V15.9167C23.8333 15.0459 23.1208 14.3334 22.25 14.3334ZM17.5 21.4584C16.6291 21.4584 15.9166 20.7459 15.9166 19.875C15.9166 19.0042 16.6291 18.2917 17.5 18.2917C18.3708 18.2917 19.0833 19.0042 19.0833 19.875C19.0833 20.7459 18.3708 21.4584 17.5 21.4584ZM19.9541 14.3334H15.0458V12.75C15.0458 11.3963 16.1462 10.2959 17.5 10.2959C18.8537 10.2959 19.9541 11.3963 19.9541 12.75V14.3334Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
<a href="https://blog.camille-binet.com" target="_blank">
https://blog.camille-binet.com</a>
                     </p>
                 </article>

                 <article>
                 <p><svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(90 17 17)" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M22.25 14.3334H21.4583V12.75C21.4583 10.565 19.685 8.79169 17.5 8.79169C15.315 8.79169 13.5416 10.565 13.5416 12.75V14.3334H12.75C11.8791 14.3334 11.1666 15.0459 11.1666 15.9167V23.8334C11.1666 24.7042 11.8791 25.4167 12.75 25.4167H22.25C23.1208 25.4167 23.8333 24.7042 23.8333 23.8334V15.9167C23.8333 15.0459 23.1208 14.3334 22.25 14.3334ZM17.5 21.4584C16.6291 21.4584 15.9166 20.7459 15.9166 19.875C15.9166 19.0042 16.6291 18.2917 17.5 18.2917C18.3708 18.2917 19.0833 19.0042 19.0833 19.875C19.0833 20.7459 18.3708 21.4584 17.5 21.4584ZM19.9541 14.3334H15.0458V12.75C15.0458 11.3963 16.1462 10.2959 17.5 10.2959C18.8537 10.2959 19.9541 11.3963 19.9541 12.75V14.3334Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
<a href="https://work.camille-binet.com" target="_blank">
https://work.camille-binet.com</a>
                     </p>
                 </article>

               

           </div>
<div className="mt-4 m-0 p-0">
<h2>Réseaux</h2>
 
 
                 <p><svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" transform="rotate(90 17 17)" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M22.25 14.3334H21.4583V12.75C21.4583 10.565 19.685 8.79169 17.5 8.79169C15.315 8.79169 13.5416 10.565 13.5416 12.75V14.3334H12.75C11.8791 14.3334 11.1666 15.0459 11.1666 15.9167V23.8334C11.1666 24.7042 11.8791 25.4167 12.75 25.4167H22.25C23.1208 25.4167 23.8333 24.7042 23.8333 23.8334V15.9167C23.8333 15.0459 23.1208 14.3334 22.25 14.3334ZM17.5 21.4584C16.6291 21.4584 15.9166 20.7459 15.9166 19.875C15.9166 19.0042 16.6291 18.2917 17.5 18.2917C18.3708 18.2917 19.0833 19.0042 19.0833 19.875C19.0833 20.7459 18.3708 21.4584 17.5 21.4584ZM19.9541 14.3334H15.0458V12.75C15.0458 11.3963 16.1462 10.2959 17.5 10.2959C18.8537 10.2959 19.9541 11.3963 19.9541 12.75V14.3334Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
Linkedin
                     </p>
                  
</div>
        </div>
     </section>
    );
  }
  
  export default Liens;
  