
function Actualites() {
    return (
      <article className="col">
       <h2> Actualités</h2>
      <div className="card">

       <div className="card-body">
       
      

       <ul>
       <li> ---  </li>
       <li>---</li>
       <li>--- </li>
       </ul>

       </div>
       </div>
      
       
       </article>
    );
  }
  
  export default Actualites;
  