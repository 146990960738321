function Fonctions() {
    return (
        <section id="fonctions" className="container-fluid  ">
        <article> 

        <div className=" mb-3 "  >
              <div className="row  p-4 row-cols-sm-2 row-cols-xs-2 row-cols-lg-2 ">
                <div className="col p-0 m-0 text-end"><p className="me-4">Bonjour</p>
                  
                </div>
                <div className="col  p-0 m-0 text-start">
                  <div className="card-body">
             
                  <svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M20.4583 15.3125V12.5417C20.4583 12.1062 20.1021 11.75 19.6667 11.75H10.1667C9.73125 11.75 9.375 12.1062 9.375 12.5417V20.4583C9.375 20.8938 9.73125 21.25 10.1667 21.25H19.6667C20.1021 21.25 20.4583 20.8938 20.4583 20.4583V17.6875L23.625 20.8542V12.1458L20.4583 15.3125Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(7 7)"/>
</clipPath>
</defs>
</svg>


<svg  className="me-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" fill="#3E3E3E"/>
<g clip-path="url(#clip0_0_1)">
<path d="M22.8413 19.1758C21.8675 19.1758 20.9254 19.0175 20.0467 18.7325C19.7696 18.6375 19.4608 18.7088 19.2471 18.9225L18.0042 20.4821C15.7637 19.4133 13.6658 17.3946 12.5496 15.075L14.0933 13.7608C14.3071 13.5392 14.3704 13.2304 14.2833 12.9533C13.9904 12.0746 13.84 11.1325 13.84 10.1588C13.84 9.73125 13.4838 9.375 13.0563 9.375H10.3171C9.88958 9.375 9.375 9.565 9.375 10.1588C9.375 17.5133 15.4946 23.625 22.8413 23.625C23.4033 23.625 23.625 23.1263 23.625 22.6908V19.9596C23.625 19.5321 23.2688 19.1758 22.8413 19.1758Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="19" height="19" fill="white" transform="translate(7 7)"/>
</clipPath>
</defs>
</svg>



<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="17" cy="17" r="17" fill="#3E3E3E"/>
<path d="M10 20.8754V24H13.1246L22.34 14.7846L19.2154 11.66L10 20.8754ZM24.7563 12.3683C25.0812 12.0433 25.0812 11.5184 24.7563 11.1934L22.8066 9.24372C22.4816 8.91876 21.9567 8.91876 21.6317 9.24372L20.1069 10.7685L23.2315 13.8931L24.7563 12.3683Z" fill="white"/>
</svg>

                  </div>
                </div>
              </div>
            </div>



            
            


        </article>
        </section>
    );
  }
  
  export default Fonctions;
  