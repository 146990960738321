function Loisirs() {
  return (
    <article className=" loisirs "> <h2> Loisirs</h2>
      <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2 m-0 p-0  ">

        <div className="card col m-0 p-0">
          <div className="card-body">
            <p>Randonnée</p>
          </div>
        </div>


        <div className="card col  m-0 p-0">
          <div className="card-body">
            <p>Natation
            </p></div></div>

        <div className="card col  m-0 p-0">
          <div className="card-body">
            <p>Basket
            </p></div></div>


        <div className="card col  m-0 p-0">
          <div className="card-body">
            <p>Dessin
            </p></div></div>

        <div className="card col  m-0 p-0">
          <div className="card-body">
            <p>Craft</p>
          </div>
        </div>

        <div className="card col  m-0 p-0">
          <div className="card-body">
            <p>Jeux Vidéos</p>
          </div>
        </div>


      </div>
    </article>
  );
}

export default Loisirs;
