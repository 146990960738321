function Diplomes() {
    return (
        <section >
        <div className=" m-0 row">

        <article id="formations" className="col m-0 p-0">

        <h2>  Diplômes</h2>
        
        <div className="card">
        <div className="card-body">

        <p>Infographiste multimédia</p>

        </div></div>

        <div className="card">
        <div className="card-body">
        <p>Concepteur / développeur d’applications</p>
        </div></div>
        </article>
        
        </div>
        </section>
    );
  }
  
  export default Diplomes;
  