
function Streaming() {
    return (
      <article className="col">
        <h2>Streaming</h2>
       <p>Espace de co-working</p>
      <div className="card">
    
       <div className="card-body">
       
    
    

       </div>
       </div>
      
       
       </article>
    );
  }
  
  export default Streaming;
  