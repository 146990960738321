
import './assets/css/bootstrap.css';
import './assets/css/style.css';   

import Header from './components/structure/Header';
import Content from './components/structure/Content';
import Footer from './components/structure/Footer';
import Nav from './components/structure/Nav';
function App() {
  return (
    <div className="App">
    <Nav></Nav>
     <Header></Header>
     <Content></Content>
     <Footer></Footer>   
       
    </div>
  );
}

export default App;
